<template>
  <v-sheet
    color="grey lighten-2"
    class="d-flex align-center justify-center"
    height="100%"
  >
    <v-form ref="loginForm" class="mx-2" @submit.prevent="login()">
      <v-card class="px-5 py-5 pb-10 mt-n10" elevation="8">
        <v-card-title class="d-flex flex-column">
          <img width="200" src="/eConsenso.png" />
          <div class="text-subtitle-1">
            Acesso Empresarial
          </div>
        </v-card-title>
        <div class="d-flex flex-column justify-center px-5">
          <v-text-field
            v-model="loginData.email"
            color="primary"
            label="E-Mail *"
            prepend-inner-icon="mdi-email-outline"
            hide-details="auto"
            class="mb-5"
            filled
            :disabled="loadingLogin"
            :rules="[$rules.required, $rules.email]"
          />
          <v-text-field
            v-model="loginData.password"
            color="primary"
            :append-icon="seePassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="seePassword = !seePassword"
            :type="seePassword ? 'text' : 'password'"
            label="Senha *"
            class="mb-5"
            filled
            prepend-inner-icon="mdi-key-variant"
            hide-details="auto"
            :disabled="loadingLogin"
            :rules="[$rules.required, $rules.passwordMin]"
          />
          <div
            class="d-flex align-center"
            style="width: 100%"
          >
            <v-btn
              class="px-1 py-5"
              small
              text
              color="info darken-2"
              @click="$refs.forgotPassword.open()"
              :disabled="loadingLogin"
            >
              Esqueci minha senha
            </v-btn>
            <v-spacer />
            <v-btn color="success" dark :loading="loadingLogin" type="submit">
              Entrar
            </v-btn>
          </div>
        </div>
      </v-card>
      <!-- <div class="mt-8 mx-1 d-flex align-center justify-center text-subtitle-1 black--text font-weight-medium">
        <span>
          Ainda não tem uma conta?
        </span>
        <v-btn
          small class="ml-2" elevation="5"
          color="info black--text"
          :to="{name: 'register'}"
          :disabled="loadingLogin"
        >
          Registre-se aqui
        </v-btn>
      </div> -->
    </v-form>
    
    <v-dialog v-model="alertApp" max-width="440px">
      <v-card class="pa-4">
        <v-card-title class="text-h5 break pb-4 d-flex justify-center text-center font-weight-bold">
          Acesso Restrito
        </v-card-title>
        <v-card-title class="text-subtitle-1 break pt-0 pb-4 text-center">
          <div>
            Esta Plataforma do eCONSENSO é de uso exclusivo para <b>Contas Empresariais</b>.
          </div>
        </v-card-title>
        <v-card-text class="break text-body-2 grey--text text--darken-3 text-justify pb-6">
          Para acessar sua Conta Pessoal do eCONSENSO, <b>instale o aplicativo em seu celular</b>. Clique no botão abaixo para saber mais.
        </v-card-text>

        <v-card-actions class="pt-0 pb-4">
          <v-btn
            color="success" block
            :to="{name: 'landing-install-app'}"
          >
            Mais informações
            <v-icon right>mdi-open-in-new</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <ForgotPassword ref="forgotPassword" />
  </v-sheet>
</template>

<script>
import ForgotPassword from "@/components/ForgotPassword.vue";

export default {
  name: "Login",

  components: {
    ForgotPassword,
  },

  data: () => ({
    alertApp: false,
    loading: false,
    loadingLogin: false,
    seePassword: false,
    checkbox: false,
    loginData: {
      email: "",
      password: "",
    },
  }),
  methods: {
    login() {
      if (this.$refs.loginForm.validate()) {
        this.loadingLogin = true;
        this.$axios
          .put("/login/web", this.loginData)
          .then((response) => {
            this.$store.commit("setUser", response.data);
            this.$router.replace({
              name: "app",
            });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 401) {
                this.$showMessage("error", "Senha Incorreta");
                return;
              } else if (error.response.status == 404) {
                this.$showMessage("error", "Email Incorreto");
                return;
              } else if (error.response.status == 400) {
                this.alertApp = true;
                return;
              }
            }
            this.$showMessage("error", "Falha Inesperada no Login");
          })
          .finally(() => {
            this.loadingLogin = false;
          });
      }
    },
  },
  created() {
    if (this.$store.state.logged) {
      this.$router.replace({ name: "app" });
    }
  },
};
</script>

<style scoped>
.break {
  word-break: break-word;
}
</style>