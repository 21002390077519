<template>
<v-dialog v-model="showing" max-width="500" :persistent="loadingSave">
    <v-form
      ref="forgotPasswordForm"
      class="grey lighten-1 d-flex justify-center align-center"
      @submit.prevent="passwordRecovery()"
    >
      <v-card align="start" width="500">
        <v-card-title class="text-h5 font-weight-medium">
          Esqueceu sua senha?
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="font-weight-medium">
            Sem problema. Informe seu e-mail que lhe enviaremos instruções para trocar sua senha.
          </p>
          <v-text-field
            v-model="forgot_password.email"
            label="E-Mail Utilizado"
            filled
            :rules="[$rules.required, $rules.email]"
            :disabled="loadingSave"
          />
        </v-card-text>
        <v-card-actions class="mt-0 pt-0">
          <v-spacer />
          <v-btn
            text
            large
            type="submit"
            :disabled="loadingSave"
            @click="showing = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green"
            dark
            large
            type="submit"
            :loading="loadingSave" 
          >
            Enviar Instruções
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
</v-dialog>
</template>
<script>
export default {
  name: "ForgotPassword",

  data: () => ({
    showing: false,
    loading: false,
    loadingSave: false,
    forgot_password: {
      email: "",
    },
  }),
  methods: {
    passwordRecovery() {
      if (this.$refs.forgotPasswordForm.validate()){
        this.loadingSave = true;
        this.$axios
        .put("/forgot-password/" + this.forgot_password.email)
        .then((response) => {
          this.$showMessage("success", "Instruções enviadas para o seu E-Mail");
          this.showing = false;
          this.$refs.forgotPasswordForm.reset();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 404) {
              this.$showMessage("warning", "Não encontramos nenhuma conta com esse E-Mail");
              return;
            }
            this.$showMessage("error", "Falha Inesperada no envio das Instruções");
          }
        })
        .finally(() => {
          this.loadingSave = false;
        })
      }
    },
    open() {
      this.showing = true
    }
  },
};
</script>